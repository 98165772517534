@font-face {
  font-family: "Pattanakarn Light";
  src: url("/assets/fonts/Pattanakarn Light.ttf");
}

@font-face {
  font-family: "Pattanakarn Regular";
  src: url("/assets/fonts/Pattanakarn Regular.ttf");
}

@font-face {
  font-family: "Pattanakarn SemiBold";
  src: url("/assets/fonts/Pattanakarn SemiBold.ttf");
}

@font-face {
  font-family: "Pattanakarn Bold";
  src: url("/assets/fonts/Pattanakarn Bold.ttf");
}

@font-face {
  font-family: "Pattanakarn Black";
  src: url("/assets/fonts/Pattanakarn Black.ttf");
}

@font-face {
  font-family: "Nasalizatoin Regular";
  src: url("/assets/fonts/nasalization-rg.otf");
}

body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
  // border: solid 1px red;
}

// .btn {
//   font-family: "Pattanakarn Regular";
//   text-decoration: none;
//   color: #8deb17;
//   border: solid 1px #8deb17;
// }

.wrapper {
  max-width: 1600px;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.wrapper-fullwidth {
  padding-left: 2rem;
  padding-right: 2rem;
}

h2 {
  font-family: "Pattanakarn Regular", sans-serif;
}

.font-black {
  font-family: "Pattanakarn Black", sans-serif;
}

.wrapper {
  h2 {
    text-transform: uppercase;
    font-size: 44px;
    line-height: 44px;
    margin: 0;

    @media (max-width: 600px) {
      font-size: 36px;
    }
  }
}

.half-half {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 600px) {
    display: block;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
