.Utilities {
  background: #000;

  .wrapper {
    padding-top: 4rem;
    padding-bottom: 4rem;

    h2 {
      color: #fff;
      margin-bottom: 3rem;
    }

    .grid-main {
      display: flex;
      gap: 2rem;

      .grid-col {
        width: 33%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .grid {
          color: #fff;
          border-radius: 20px;
          padding: 1rem 2rem;
          flex-grow: 1;
          transition: 0.3s;
          // cursor: pointer;
          border: solid 3px transparent;

          .grid-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3,
            .grid-num {
              font-family: "Pattanakarn Regular";
              font-size: 24px;
              margin: 0;
              font-weight: bold;
            }
          }

          p {
            font-size: 18px;
          }

          em {
            font-style: normal;
            font-family: "Pattanakarn Regular";
          }

          p,
          li {
            color: rgb(210, 206, 200);
            font-family: "Pattanakarn Regular";
          }

          &.copper {
            background: #151615;
            border-color: #339412;
            box-shadow: 13px 5px 0 #339412;

            .grid-header {
              color: rgb(85, 255, 26);
            }

            em {
              color: rgb(85, 255, 26);
            }
          }

          &.silver {
            background: #282e2a;
            border-color: #fff;
            box-shadow: 13px 5px 0 #fff;

            em {
              color: #fff;
            }
          }

          &.gold {
            background: #272819;
            border-color: rgba(255, 218, 88, 1);
            box-shadow: 13px 5px 0 rgba(255, 218, 88, 1);

            .grid-header {
              color: rgb(255, 218, 87);
            }

            em {
              color: rgb(255, 218, 87);
            }
          }

          &.platinum {
            background: #0c2b25;
            border-color: rgba(128, 225, 255, 1);
            box-shadow: 13px 5px 0 rgba(128, 225, 255, 1);

            .grid-header {
              color: rgb(115, 221, 255);
            }

            em {
              color: rgb(115, 221, 255);
            }
          }

          &.diamond {
            background: #1e1725;
            border-color: rgb(197, 122, 254);
            box-shadow: 13px 5px 0 rgb(197, 122, 254);

            .grid-header {
              color: rgb(197, 122, 254);
            }

            em {
              color: rgb(197, 122, 254);
            }
          }

          // &:hover {
          //   background: rgb(85, 255, 26);
          //   border-color: #339412;
          //   box-shadow: 13px 5px 0 #339412;

          //   p,
          //   li,
          //   .grid-header,
          //   em {
          //     color: #000;
          //   }
          // }
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .Utilities {
    .wrapper {
      .grid-main {
        flex-direction: column;

        .grid-col {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .Utilities {
    .wrapper {
      .grid-main {
        .grid-col {
          .grid {
            padding: 1rem;
          }
        }
      }
    }
  }
}
