.Mission {
    background: #41ff03;
    padding: 4rem 0;

    .mission-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3rem;

        img {
            max-width: 120px;
        }
    }

    .mission-statement {
        font-family: "Pattanakarn Light", sans-serif;
        font-size: 45px;
        line-height: 56px;
        text-align: center;

        @media (max-width: 600px) {
            font-size: 35px;
        }
    }
}
