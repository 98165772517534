.Introdcution {
  background: url("/assets/introduction-bg.png") no-repeat center / cover;
  color: #fff;
  padding: 4rem 0;

  .hoodie-slider {
    position: sticky;
    top: 0px;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: -10rem;

    @media (max-width: 767px) {
      height: unset;
      position: relative;
      margin-top: unset;
    }

    .eight91-unique-items {
      display: flex;
      align-items: center;
      max-width: 400px;
      position: absolute;
      bottom: 4rem;
      font-weight: 300;
      line-height: 26px;
      font-size: 14px;

      @media (max-width: 767px) {
        display: none;
      }

      img {
        max-width: 90px;
      }
    }
  }

  .swiper {
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .hoodie-model-main {
        position: relative;
        // width: 100%;
        // overflow: hidden;
      }

      .text-bubbles {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        font-size: 0.9rem;
        // display: none;

        @media (max-width: 767px) {
          display: none;
        }

        .text-bubble {
          position: absolute;
          max-width: 280px;
          text-align: center;
          transition: 0.3s;
          opacity: 0;

          &.show-text-bubble {
            opacity: 1;
          }

          &:nth-child(1) {
            left: -20.5rem;
            top: 0rem;
            color: #d0ffc2;

            &::after {
              content: "";
              background: url("/assets/9.png") no-repeat center/cover;
              position: absolute;
              top: 2rem;
              right: -16rem;
              width: 300px;
              height: 100px;
            }

            @media (max-width: 1200px) {
              left: -10.5rem;

              &::after {
                display: none;
              }
            }
          }

          &:nth-child(2) {
            left: -22.5rem;
            top: 7rem;
            color: #ffb393;

            &::after {
              content: "";
              background: url("/assets/4.png") no-repeat center/cover;
              position: absolute;
              top: 0rem;
              right: -15rem;
              width: 300px;
              height: 100px;
            }

            @media (max-width: 1200px) {
              left: -10.5rem;

              &::after {
                display: none;
              }
            }
          }

          &:nth-child(3) {
            right: -16rem;
            top: 0rem;
            color: #d0ffc2;

            &::after {
              content: "";
              background: url("/assets/3.png") no-repeat center/cover;
              position: absolute;
              top: 0rem;
              left: -15rem;
              width: 300px;
              height: 100px;
            }

            @media (max-width: 1200px) {
              right: -8rem;

              &::after {
                display: none;
              }
            }
          }

          &:nth-child(4) {
            left: -20rem;
            top: 14rem;

            &::after {
              content: "";
              background: url("/assets/5.png") no-repeat center/cover;
              position: absolute;
              top: 0rem;
              right: -15rem;
              width: 300px;
              height: 100px;
            }

            @media (max-width: 1200px) {
              left: -9.5rem;

              &::after {
                display: none;
              }
            }
          }

          &:nth-child(5) {
            right: -13rem;
            top: 10rem;
            color: #ffb393;

            &::after {
              content: "";
              background: url("/assets/6.png") no-repeat center/cover;
              position: absolute;
              top: 2rem;
              left: -14rem;
              width: 300px;
              height: 150px;
            }

            @media (max-width: 1200px) {
              right: -9rem;

              &::after {
                display: none;
              }
            }
          }

          &:nth-child(6) {
            left: -21rem;
            bottom: 0;
            color: #d0ffc2;

            &::after {
              content: "";
              background: url("/assets/7.png") no-repeat center/cover;
              position: absolute;
              top: 0rem;
              right: -16rem;
              width: 300px;
              height: 100px;
            }

            @media (max-width: 1200px) {
              left: -9rem;

              &::after {
                display: none;
              }
            }
          }

          &:nth-child(7) {
            right: -16rem;
            bottom: 1rem;

            &::after {
              content: "";
              background: url("/assets/8.png") no-repeat center/cover;
              position: absolute;
              top: 0rem;
              left: -15rem;
              width: 300px;
              height: 100px;
            }

            @media (max-width: 1200px) {
              right: -10rem;

              &::after {
                display: none;
              }
            }
          }
        }
      }

      .hoodie-name {
        color: #8deb17;
        font-family: "Pattanakarn Regular";
        margin-top: 2rem;
        border: solid 2px #8deb17;
        padding: 0 1rem;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          background: #8deb17;
          color: #000;
        }
      }
    }

    .swiper-button-prev {
      bottom: 0;
      top: unset;
      right: 4rem;
      left: unset;
      background: #fff;
      width: 40px;
      height: 40px;
      border-radius: 100px;

      @media (max-width: 767px) {
        right: unset;
        left: unset;
      }

      &::after {
        font-size: 1rem;
        font-weight: bold;
        color: #000;
        margin-left: -4px;
      }
    }

    .swiper-button-next {
      bottom: 0;
      top: unset;
      background: #fff;
      width: 40px;
      height: 40px;
      border-radius: 100px;

      @media (max-width: 767px) {
        right: 0;
      }

      &::after {
        font-size: 1rem;
        font-weight: bold;
        color: #000;
        margin-right: -4px;
      }
    }

    &.hide-navigators {
      @media (min-width: 600px) {
        .swiper-button-prev,
        .swiper-button-next {
          opacity: 0 !important;
        }
      }
    }
  }

  .small-heading {
    text-align: center;
    font-size: small;

    span {
      color: #8deb17;
      position: relative;
      padding-left: 2rem;
      margin-bottom: 1rem;
      display: inline-block;

      &::before {
        content: "";
        width: 20px;
        height: 1px;
        background: #ccc;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateX(-50%);
      }
    }
  }

  h1 {
    font-family: "Playfair Display", serif;
    font-size: 40px;
    text-align: center;
    max-width: 700px;
    margin: auto;
    position: relative;
    z-index: 2;

    span {
      font-family: "Nasalizatoin Regular", sans-serif;
      text-transform: uppercase;
      color: #8deb17;
    }

    &::after {
      content: "";
      background: url("/assets/badge.png") center / cover;
      width: 100px;
      height: 100px;
      position: absolute;
      top: -3rem;
      right: -7rem;
      animation: spin 20s infinite linear;

      @media (max-width: 1200px) {
        top: 8rem;
        right: 0;
      }
    }
  }

  .modelWrapper {
    max-width: 1600px;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    justify-content: center;
  }

  .hoodie-model-main {
    display: flex;
    justify-content: center;

    .hoodie-model {
      width: 400px;
      height: 400px;
    }

    @media (max-width: 600px) {
      width: 100%;
      overflow: hidden;
    }
  }

  .gap {
    @media (min-width: 767px) {
      height: 2000px;
    }

    .text-bubbles {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .text-bubble {
        border-bottom: solid 1px #1f1c2c;
        padding-bottom: 1rem;
        transition: 0.3s;
        opacity: 0;

        &.show-text-bubble {
          opacity: 1;
        }

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(7) {
          color: #ffb393;
        }

        &:nth-child(2),
        &:nth-child(5) {
          color: #d0ffc2;
        }
      }

      @media (min-width: 767px) {
        display: none;
      }
    }
  }
}
