header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 10;
  transition: 0.3s;
  // backdrop-filter: blur(50px);
  // background: #ffffff87;

  &.sticky-header {
    background: #f6fff3;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  .wrapper-fullwidth {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    max-width: 220px;
    position: relative;
    z-index: 1;
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .menu-icon {
    max-width: 54px;
    position: relative;
    z-index: 1;
    background: rgba(242, 255, 238, 0.8);
    padding: 0.5rem 1rem;
    line-height: 0;
    border-radius: 3px;
    display: none;

    @media (max-width: 1200px) {
      display: block;
    }

    img {
      width: 100%;
    }
  }

  nav {
    display: inline-flex;
    gap: 1rem;

    @media (max-width: 1200px) {
      background: #ffffffeb;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding: 1rem;
      padding-top: 5rem;
      backdrop-filter: blur(10px);
      display: none;

      &.mobile-menu {
        display: flex;
      }
    }

    a {
      font-family: "Pattanakarn Regular";
      text-decoration: none;
      color: #333;
      background: rgba(242, 255, 238, 0.7);
      padding: 0 1rem;
      border-radius: 3px;
      font-size: 11px;
      text-transform: uppercase;
      transition: 0.3s;

      @media (max-width: 1200px) {
        font-size: 20px;
        background: none;
      }

      &:hover {
        background: rgba(242, 255, 238, 1);
      }
    }
  }
}
