.Mindmap {
    // background: #f2f2f0;
    padding: 4rem 0;
    font-size: 14px;
    overflow: hidden;

    .wrapper {
        position: relative;

        @media (max-width: 600px) {
            padding: 0 0.5rem;
        }
    }

    .mindmap-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas:
            "grid-01 grid-02 grid-04 grid-05"
            "grid-01 grid-03 grid-04 grid-05"
            "grid-01 grid-03 grid-04 grid-05"
            "grid-01 grid-03 grid-04 grid-05"
            "grid-01 grid-03 grid-04 grid-05"
            "grid-01 grid-03 grid-04 grid-05"
            "grid-01 grid-03 grid-04 grid-05"
            "grid-01 grid-03 grid-06 grid-06";
        gap: 1rem;
        min-height: 500px;

        @media (max-width: 1200px) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                "grid-01 grid-02"
                "grid-03 grid-04"
                "grid-05 grid-06";
        }

        @media (max-width: 600px) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                "grid-01 grid-02"
                "grid-03 grid-04"
                "grid-05 grid-06";
            gap: 0.5rem;
        }

        .grid-item {
            background: pink;
            min-height: 220px;
            padding: 1rem;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            cursor: pointer;
            transition: 0.3s;
            overflow: auto;

            @media (max-width: 1200px) {
                min-height: 206px;
            }

            @media (max-width: 600px) {
                justify-content: flex-start;
            }

            .close-grid {
                display: none;
            }

            &.active-grid {
                position: absolute;
                width: 96%;
                height: 100%;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                transition: 0.3s;
                padding: 3rem;
                justify-content: flex-start;
                background-size: 30% !important;

                @media (min-width: 601px) {
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                        justify-content: center !important;
                    }
                }

                @media (max-width: 600px) {
                    padding: 2rem;

                    &:nth-child(1),
                    &:nth-child(2) {
                        background-image: none !important;
                    }

                    &:nth-child(4) {
                        background-position: bottom right !important;
                        background-size: 80% !important;
                    }

                    &:nth-child(3) {
                        background-position: bottom right !important;
                        background-size: 100% !important;
                    }

                    &:nth-child(5) {
                        background: url("../assets/mindmap-05-invert.png") no-repeat bottom right / contain, #ffb393 !important;
                    }

                    &:nth-child(6) {
                        background-position: bottom right !important;
                        background-size: 100% !important;
                    }
                }

                .serial {
                    display: none;

                    @media (max-width: 600px) {
                        font-size: 40px;
                        line-height: 40px;
                    }
                }

                .title {
                    @media (max-width: 600px) {
                        font-size: 22px;
                        line-height: 22px;
                    }
                }

                .close-grid {
                    display: block;
                    position: absolute;
                    top: 1rem;
                    right: 3rem;
                    font-size: 4rem;

                    @media (max-width: 600px) {
                        top: 0rem;
                        right: 2rem;
                    }
                }

                .description {
                    max-width: 60%;

                    @media (max-width: 600px) {
                        max-width: 100%;
                    }

                    strong {
                        font-family: "Pattanakarn Bold", sans-serif;
                    }

                    p,
                    li {
                        font-family: "Pattanakarn Regular", sans-serif;
                        line-height: 1.6;
                    }

                    li {
                        margin-bottom: 1rem;
                    }
                }
            }

            .serial,
            .title {
                font-family: "Pattanakarn SemiBold", sans-serif;
            }

            .serial {
                font-size: 70px;
                line-height: 70px;

                @media (max-width: 600px) {
                    font-size: 30px;
                    line-height: 30px;
                }
            }

            .title {
                font-size: 30px;
                line-height: 40px;

                @media (max-width: 600px) {
                    font-size: 18px;
                    line-height: 22px;
                }
            }

            &:nth-child(1) {
                grid-area: grid-01;
                background: url("../assets/mindmap-01-inactive.png") no-repeat center right / contain, #ffb393;

                &:hover {
                    background: url("../assets/mindmap-01.png") no-repeat center right / contain, #ffb393;
                }

                @media (max-width: 600px) {
                    background-size: 70%;
                    background-position: bottom right;
                }
            }

            &:nth-child(2) {
                grid-area: grid-02;
                background: url("../assets/mindmap-02-inactive.png") no-repeat center right / 70%, #7cff51;

                &:hover {
                    background: url("../assets/mindmap-02.png") no-repeat center right / 70%, #7cff51;
                }

                @media (max-width: 600px) {
                    background-size: 70%;
                    background-position: bottom right;
                }
            }

            &:nth-child(3) {
                grid-area: grid-03;
                background: url("../assets/mindmap-03-inactive.png") no-repeat top right / contain, #b8b8b8;

                &:hover {
                    background: url("../assets/mindmap-03.png") no-repeat top right / contain, #b8b8b8;
                }

                @media (max-width: 600px) {
                    background: url("../assets/mindmap-03-inactive-tilt.png") no-repeat bottom right / contain, #b8b8b8;

                    &:hover {
                        background: url("../assets/mindmap-03-tilt.png") no-repeat bottom right / contain, #b8b8b8;
                    }
                }
            }

            &:nth-child(4) {
                grid-area: grid-04;
                background: url("../assets/mindmap-04-inactive.png") no-repeat center right / contain, #b8b8b8;

                &:hover {
                    background: url("../assets/mindmap-04.png") no-repeat center right / contain, #b8b8b8;
                }
            }

            &:nth-child(5) {
                grid-area: grid-05;
                background: url("../assets/mindmap-05-inactive.png") no-repeat top right / 100%, #ffb393;

                &:hover {
                    background: url("../assets/mindmap-05.png") no-repeat top right / 100%, #ffb393;
                }

                @media (max-width: 600px) {
                    background: url("../assets/mindmap-05-invert.png") no-repeat bottom right / contain, #ffb393;

                    &:hover {
                        background: url("../assets/mindmap-05-invert.png") no-repeat bottom right / contain, #ffb393;
                    }
                }
            }

            &:nth-child(6) {
                grid-area: grid-06;
                background: url("../assets/mindmap-06-inactive.png") no-repeat center right / contain, #7cff51;
                justify-content: flex-start;

                &:hover {
                    background: url("../assets/mindmap-06.png") no-repeat center right / contain, #7cff51;
                }

                @media (max-width: 600px) {
                    background-position: bottom right;

                    &:hover {
                        background-position: bottom right;
                    }
                }
            }
        }
    }
}
