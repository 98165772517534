.Ourstory {
  background: #000;

  .wrapper {
    padding-top: 4rem;
    padding-bottom: 4rem;

    h2 {
      color: #fff;
      margin-bottom: 3rem;
    }

    .values {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 4rem;

      @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 600px) {
        grid-template-columns: 1fr;
      }

      .value {
        padding: 2rem 1rem;
        text-align: center;
        position: relative;
        min-height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        h3 {
          font-family: "Pattanakarn Bold", sans-serif;
          font-size: 36px;
          line-height: 40px;
          margin: 0;
          position: relative;
          z-index: 1;
          margin-top: 1rem;
        }

        p {
          text-transform: uppercase;
          margin: 0;
          line-height: 2;
          overflow: auto;
          border: solid 2px #fff;
          padding: 1rem;
          width: 86%;
          height: 93%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding-top: 5rem;
          font-size: 0.95rem;
        }

        &:nth-of-type(1) p {
          background: lighten(#cdfdc6, 1%);
        }

        &:nth-of-type(2) p {
          background: lighten(#9ae3fd, 1%);
        }

        &:nth-of-type(3) p {
          background: lighten(#f1a7ff, 10%);
        }

        &:nth-of-type(4) p {
          background: lighten(#fff79a, 10%);
        }

        img {
          max-width: 150px;
        }

        @media (min-width: 768px) {
          &:not(:last-of-type):after {
            content: "";
            position: absolute;
            top: 50%;
            right: -2.1rem;
            transform: translateY(-50%);
            width: 2px;
            height: 150px;
            background: #fff;
          }
        }

        &:nth-child(1) {
          background: #5c8357;
        }
        &:nth-child(2) {
          background: #4392ae;
        }
        &:nth-child(3) {
          background: #983fa9;
        }
        &:nth-child(4) {
          background: #9b922e;
        }
      }
    }
  }

  .fullwidth-gif {
    line-height: 0;

    img {
      width: 100%;
    }
  }
}
