.Hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: #f2ffee;
  gap: 2rem;
  position: relative;

  @media (max-width: 600px) {
    gap: 1rem;
  }

  .hover-text {
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: lighter;
    margin: 0 1rem;

    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }

  .hero-text {
    max-width: 1000px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 1;

    &::after {
      content: "Studio";
      position: absolute;
      bottom: 28px;
      right: -134px;
      transform: rotate(-90deg);
      font-size: 2rem;
      font-family: "Pattanakarn Light";

      @media (min-width: 1921px) {
        font-size: 2.4rem;
        right: -350px;
      }

      @media (max-width: 900px) {
        right: -84px;
      }

      @media (max-width: 1280px) {
        font-size: 1.2rem;
        right: -54px;
        bottom: 20px;
      }

      @media (max-width: 600px) {
        font-size: 1rem;
        right: -40px;
        bottom: 8px;
      }
    }

    img {
      max-height: 220px;
      filter: brightness(0.2);

      @media (min-width: 1921px) {
        max-height: 300px;
      }

      @media (max-width: 1280px) {
        max-height: 140px;
      }

      @media (max-width: 900px) {
        max-height: 100px;
      }

      @media (max-width: 600px) {
        max-height: 60px;
      }

      &:hover {
        filter: brightness(1);
      }

      &:nth-child(1),
      &:nth-child(3) {
        margin-right: 2rem;

        @media (max-width: 900px) {
          margin-right: 1rem;
        }

        @media (max-width: 600px) {
          margin-right: 0.2rem;
        }
      }

      &:nth-child(4) {
        display: inline-block;
        position: relative;
      }
    }
  }

  .mint-button {
    position: relative;
    z-index: 1;
    margin-top: 2rem;

    .btn-svg-outline .layer1,
    .btn-svg-outline .layer2 {
      stroke: #42FF00;
      fill: #42FF00;
    }

    .btn-svg-outline text {
      fill: #000;
      font-family: "Pattanakarn Regular";
    }

    &:hover {
      .btn-svg-outline .layer1,
      .btn-svg-outline .layer2 {
        stroke: #000;
        fill: #000;
      }

      .btn-svg-outline text {
        fill: #42FF00;
      }
    }
  }

  .smokes {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    img {
      max-width: 1000px;
      position: absolute;

      &:first-of-type {
        left: -15rem;
        bottom: -27rem;
      }

      &:last-of-type {
        right: -18rem;
        top: -28rem;
      }
    }
  }
}
