.collectionMain {
  .verticalSliderMain {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    @media (max-width: 1023px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }

    .verticalSlider {
      height: 590px;
      border: solid 10px #fff;
      transition: 0.3s;

      @media (max-width: 600px) {
        height: 300px;
      }

      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background: #000;

        img {
          width: 100%;
        }

        video {
          width: 113%;
        }
      }

      &:hover {
        transform: scale(1.03);
        z-index: 5;
      }
    }

    .swiper {
      width: 100%;

      .swiper-button-next:after,
      .swiper-button-prev::after {
        transform: rotate(88deg);
        color: #fff;

        @media (max-width: 600px) {
          font-size: 1.6rem;
        }
      }

      .swiper-button-next {
        top: unset;
        bottom: 20px;
        color: black;
        left: 50%;
        transform: translateX(-50%);
      }

      .swiper-button-prev {
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        color: black;
      }

      @media (max-width: 1023px) {
        &:nth-child(4),
        &:nth-child(5) {
          transform: translateX(50%);
        }
      }

      @media (max-width: 600px) {
        &:nth-child(4) {
          transform: translateX(0);
        }
        &:last-of-type {
          transform: translateX(50%);
        }
      }
    }

    .copperColor::before,
    .silverColor::before,
    .goldColor::before,
    .platinumColor::before,
    .diamondColor::before {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      transition: 0.5s;
      display: flex;
      justify-content: center;
      align-items: center;
      writing-mode: vertical-lr;
      text-orientation: upright;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
      font-family: "Pattanakarn Bold", sans-serif;
    }

    .copperColor::before {
      content: "copper";
      background: #d08b4b;
      box-shadow: inset 0 0 0 20px #dca572;
      font-size: 34px;

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }

    .silverColor::before {
      content: "silver";
      background: #e785f9;
      box-shadow: inset 0 0 0 20px #efaefb;
      font-size: 34px;

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }

    .goldColor::before {
      content: "Gold";
      background: #ffda7d;
      box-shadow: inset 0 0 0 20px #ffefc2;
      font-size: 44px;

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    .platinumColor::before {
      content: "Platinum";
      background: #5bee76;
      box-shadow: inset 0 0 0 20px #b6fdb2;
      font-size: 26px;

      @media (max-width: 600px) {
        font-size: 11px;
      }
    }

    .diamondColor::before {
      content: "Diamond";
      background: #7cdefd;
      box-shadow: inset 0 0 0 20px #a8e9fe;
      font-size: 28px;

      @media (max-width: 600px) {
        font-size: 12px;
      }
    }

    .copperColor:hover::before {
      opacity: 0;
    }

    .silverColor:hover::before {
      opacity: 0;
    }

    .goldColor:hover::before {
      opacity: 0;
    }

    .platinumColor:hover::before {
      opacity: 0;
    }

    .diamondColor:hover::before {
      opacity: 0;
    }
  }
}
