.Socials {
  background: #f2ffee;
  padding: 4rem 0;

  p {
    font-size: 24px;
    max-width: 580px;
  }

  .social-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 3rem;
    padding-left: 1rem;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      padding-left: 0.5rem;
    }

    .social-link {
      background: #545454;

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: solid 7px #000;
        padding: 0.5rem 2rem;
        color: #000;
        text-decoration: none;
        transition: 0.3s;
        height: 100%;
        margin: -1rem 1rem 1rem -1rem;

        .social-link-title {
          font-family: "Pattanakarn Regular", sans-serif;
          line-height: 30px;
        }

        &:hover {
          margin: 0;
        }

        &.twitter {
          background: #94dbff;
        }

        &.instagram {
          background: #ffde84;
        }

        &.discord {
          background: #de5cf7;
        }

        &.email {
          background: #6dfc65;
        }

        h3 {
          margin: 0.2rem 0;
          font-size: 30px;
        }

        img {
          max-width: 100px;
        }

        @media (max-width: 600px) {
          margin: -0.5rem 0.5rem 0.5rem -0.5rem;
          padding: 0.5rem 1rem;
          
          img {
            max-width: 70px;
          }
        }
      }
    }
  }
}
