.About {
    background: #f2f2f0;
    padding: 4rem 0;

    p {
        font-family: "Pattanakarn Regular", sans-serif;
        font-size: 24px;
        line-height: 34px;
    }

    .half-half {
        align-items: center;

        @media (max-width: 1200px) {
            gap: 2rem;
        }

        div:last-of-type {
            text-align: center;
        }
    }

    .button {
        position: relative;
        z-index: 1;
        margin-top: 2rem;
        display: inline-block;

        .btn-svg-outline .layer1,
        .btn-svg-outline .layer2 {
            stroke: #42ff00;
            fill: #42ff00;
        }

        .btn-svg-outline text {
            fill: #000;
            font-family: "Pattanakarn Regular";
        }

        &:hover {
            .btn-svg-outline .layer1,
            .btn-svg-outline .layer2 {
                stroke: #000;
                fill: #000;
            }

            .btn-svg-outline text {
                fill: #42ff00;
            }
        }

        @media (max-width: 500px) {
            text-align: center;
            display: block;
        }
    }

    img {
        width: 100%;
        max-width: 400px;
    }
}
